import './styles/index.scss' // MUST stay above RedirectRoutes!
import { useRpcClient } from '@gain/api/swr'
import Maintenance from '@gain/components/maintenance'
import { SlotProvider } from '@gain/components/slot'
import { SnackbarProvider } from '@gain/components/snackbar'
import { AuthContext, useAuthContextValue } from '@gain/modules/auth'
import { HeadProvider } from '@gain/modules/head'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { enGB } from 'date-fns/locale/en-GB'
import { Route } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { QueryParamProvider } from 'use-query-params'

import DialogProvider from '../../app/src/common/dialog'
import configureStore from './redux/store/store'
import Routes from './routes'
import cmsTheme from './theme/theme'
import HelmetConfig from './util/helmetConfig'
import cmsRpcClient, { appRpcClient } from './util/rpcClient'
import { swrErrorHandler } from './util/swr-error-handler'
import useLogout from './util/useLogout'

const { store, history } = configureStore()

export { history, store }

export default function App() {
  const rpcClientFetcherT = useRpcClient(cmsRpcClient)
  const appRpClientFetcher = useRpcClient(appRpcClient)
  const authContextValue = useAuthContextValue()
  const logout = useLogout(authContextValue, appRpClientFetcher)

  // Configure RPC client to perform client side logout on 401
  // Adding dummy comment to trigger a new build
  cmsRpcClient.onUnauthorized(logout)

  return (
    <LocalizationProvider
      adapterLocale={enGB}
      dateAdapter={AdapterDateFns}>
      <SWRConfig
        value={{
          onError: swrErrorHandler(store),
          fetcher: rpcClientFetcherT,
          revalidateOnMount: true,
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }}>
        <AuthContext.Provider value={authContextValue}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={cmsTheme}>
              <HeadProvider defaultHeadProps={HelmetConfig}>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <SnackbarProvider>
                    <Maintenance client={cmsRpcClient}>
                      <DialogProvider>
                        <SlotProvider>
                          <Routes />
                        </SlotProvider>
                      </DialogProvider>
                    </Maintenance>
                  </SnackbarProvider>
                </QueryParamProvider>
              </HeadProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </AuthContext.Provider>
      </SWRConfig>
    </LocalizationProvider>
  )
}
